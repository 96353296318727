import React from 'react';
import './confirmation_modal.css'; // For styling the modal

const ConfirmationModal = ({ isOpen, message, onConfirm, onCancel, isError }) => {
  if (!isOpen) return null; // Do not render the modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className='message'>
            <pre>{message}</pre>
        </div>
        <div className="modal-buttons">
          {isError ? null : (
            <button className="btn-confirm" onClick={onConfirm}>
                Confirm
            </button>)}
          <button className="btn-cancel" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
