import axios from 'axios';

const baseURL = 'http://app.redcapestudios.com/';

const instance = axios.create({
  baseURL: baseURL,
  maxRedirects: 0,
  withCredentials: true,  // This ensures that cookies are included in cross-origin requests
});

export default instance;