import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AxiosInstance from './AxiosInstance';
import Header from './Header';
import ConfirmationModal from './ConfirmationModal';
import ImageUploadComponent from './ImageUploadComponent';
import "./projectdetail.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus, faPencilAlt, faUndoAlt, faSave } from '@fortawesome/free-solid-svg-icons';

const ProjectDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const fileInputRefs = useRef([]);
  const [projectInfo, setProjectInfo] = useState({ name: '', bannerUrl: '' });

  const initialFormState = {
    projectId: '',
    assetType: '',
    assetName: '',
    assetTypeOptions: [],
    assetNameOptions: [],  // Track asset names per form
    process: '',
    status: '',
    cManDays:'',
    manDays: '',
    image: null,
    imageName: '',
    description: '',
    collapsed: false,
    prefilled: false,
    tracked: false
  };
  // const initialFormStateRef = useRef(initialFormState);

  const [forms, setForms] = useState([initialFormState]);
  const [formToSubmit, setFormToSubmit] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [allTypes, setAllTypes] = useState({});
  const [assetNameOptions, setAssetNameOptions] = useState({});
  const [processOptions, setProcessOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const trackingFormsRef = useRef([]);
  
  const handleBeforeUnload = (event) => {
    if (hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = '';
    }
  };


  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const controller = new AbortController();

    const fetchProjectData = async () => {
      try {
        const projectResponse = await AxiosInstance.get(`/api/project/`, {
          signal: controller.signal,
        });
        const { projects, assets, types, phases, status } = projectResponse.data;

        // DRAFTS
        const userLogsResponse = await AxiosInstance.get(`/api/daily_log/`, {
          signal: controller.signal,
        });
        const userLogs = userLogsResponse.data;

        //LOGS FOR CURRENT
        const userSubmittedLogsResponse = await AxiosInstance.get(`/api/daily_log_submitted/`, {
          signal: controller.signal,
        });
        const userSubmittedLogs = userSubmittedLogsResponse.data;

        // const trackingResponse = await AxiosInstance.get(`/api/asset_tracking/${id}/`, {
        //   signal: controller.signal,
        // });
        // const trackingData = trackingResponse.data;

        // trackingFormsRef.current = trackingData
        //   .filter(item => item.status_id === 2 || item.status_id === 7)
        //   .map(item => ({
        //     projectId: item.project_id,
        //     assetType: item.asset_type_id,
        //     assetName: item.asset_name,
        //     process: item.phase_id,
        //     status: item.status_id,
        //     cManDays: item.c_man_days || '0',
        //     manDays: item.man_days || '',
        //   }));

        // setProjectInfo({
        //   name: selectedProject?.name || '',
        //   bannerUrl: `../project_icon_without_title/${selectedProject?.id}_icon.jpg`,
        // });

        setProjectOptions(projects);
        setAllTypes(types);
        setProcessOptions(phases);
        setStatusOptions(status);

        setAssetNameOptions(
          assets.reduce((acc, asset) => {
            acc[asset.project_id] = acc[asset.project_id] || {};
            acc[asset.project_id][asset.asset_type_id] = acc[asset.project_id][asset.asset_type_id] || [];
            acc[asset.project_id][asset.asset_type_id].push(asset.asset_name);
            return acc;
          }, {})
        );
        
        console.log(userSubmittedLogs);
        const submittedForms = await Promise.all(
          userSubmittedLogs.map(async (log) => {
            let imageBlob = null;
            if (log.image_url) {
              const response = await fetch(`${AxiosInstance.defaults.baseURL}/api/image/${log.image_url}`);
              const blob = await response.blob();
              imageBlob = new File([blob], log.image_url.split('/').pop(), { type: blob.type });
              console.log(log.image_url);
            }

            return {
              projectId: log.project_id || '',
              assetType: log.asset_type_id || '',
              assetName: log.asset_name || '',
              process: log.phase_id || '',
              status: log.status_id || '',
              cManDays: log.c_man_days || '',
              manDays: log.man_days || '',
              description: log.description || '',
              image: imageBlob,
              imageName: '',
              collapsed: true,
              prefilled: true,
              tracked: true,
              assetTypeOptions: types[log.project_id] || [],
              assetNameOptions: assetNameOptions[log.project_id]?.[log.asset_type_id] || [],
            };
          })
        );
        // setForms(submittedForms.length > 0 ? submittedForms : [initialFormState]);

        const prefilledForms = await Promise.all(
          userLogs.map(async (log) => {
            let imageBlob = null;
            if (log.image_url) {
              const response = await fetch(`${AxiosInstance.defaults.baseURL}/api/image/${log.image_url}`);
              const blob = await response.blob();
              imageBlob = new File([blob], log.image_url.split('/').pop(), { type: blob.type });
            }

            return {
              projectId: log.project_id || '',
              assetType: log.asset_type_id || '',
              assetName: log.asset_name || '',
              process: log.phase_id || '',
              status: log.status_id || '',
              cManDays: log.c_man_days || '',
              manDays: log.man_days || '',
              description: log.description || '',
              image: imageBlob,
              imageName: '',
              collapsed: false,
              prefilled: true,
              tracked: false,
              assetTypeOptions: types[log.project_id] || [],
              assetNameOptions: assetNameOptions[log.project_id]?.[log.asset_type_id] || [],
            };
          })
        );
        // setForms(prefilledForms.length > 0 ? prefilledForms : [initialFormState]);

        const combinedForms = [...submittedForms, ...prefilledForms];
        setForms(combinedForms.length > 0 ? combinedForms : [initialFormState]);

      } catch (error) {
        if (error.name !== 'CanceledError') {
          console.error('Error fetching project data:', error);
        }
      }
    };

    fetchProjectData();
    
    // Cleanup function to abort the request when component unmounts
    return () => {
      controller.abort();
    };    
  
  }, [id, setProjectInfo]);

  useEffect(() => {
    fileInputRefs.current = fileInputRefs.current.slice(0, forms.length);
  }, [forms]);

  const handleInputChange = async (index, field, value) => {
    const newForms = [...forms];
  
    newForms[index][field] = value;
    // console.log(value)

    setForms((prevForms) => {
      const newForms = [...prevForms];
      newForms[index][field] = value;
      return newForms;
    });

    // Clear error for this field on input change
    setFormErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (newErrors[index]) {
        delete newErrors[index][field];
      }
      return newErrors;
    });

    if (field === 'projectId') {
      const filteredAssetTypes = allTypes[value] || [];
      newForms[index].assetType = '';
      newForms[index].assetName = '';
      newForms[index].assetTypeOptions = filteredAssetTypes; // Set asset types specific to the current form
      newForms[index].assetNameOptions = [];  // Clear asset names when project changes
      newForms[index].manDays = '';
      newForms[index].tracked = false;
    }

    if (field === 'assetType') {
      const filteredAssetNames = assetNameOptions[newForms[index].projectId]?.[value] || [];
      newForms[index].assetName = '';
      newForms[index].assetNameOptions = filteredAssetNames;
      newForms[index].manDays = '';
      newForms[index].tracked = false;
    } 
    //   else if (field === 'assetName') {
    //   newForms[index].process = '';
    //   newForms[index].status = '';
    //   newForms[index].cManDays = '';
    //   newForms[index].manDays = '';
    // } 
    else if (field === 'process' || field === 'assetName') {
    //   newForms[index].status = '';
    //   newForms[index].cManDays = '';
    //   newForms[index].manDays = '';

      const params = new URLSearchParams();
      if (newForms[index].projectId) params.append('projectId', newForms[index].projectId);
      if (newForms[index].assetType) params.append('assetType', newForms[index].assetType);
      if (newForms[index].assetName) params.append('assetName', newForms[index].assetName);
      if (newForms[index].process) params.append('process', newForms[index].process == 2 ? '1' : newForms[index].process);
      
      try {
          const controller = new AbortController();

          if( newForms[index].assetName !== '' && newForms[index].process !== '' && newForms[index].assetName !== 'Other')
          {  
            const trackingResponse = await AxiosInstance.get(`/api/asset_tracking/?${params.toString()}`, {
                signal: controller.signal,
            });
            const trackingData = trackingResponse.data;
            // console.log(trackingData[0].man_days)

            newForms[index].manDays = trackingData[0].man_days || '';
            newForms[index].tracked = newForms[index].manDays !== '';
          }
          else
          {
            console.log('Not enough info to track the asset')
            newForms[index].manDays = '';
            newForms[index].tracked = false;
          }
      
          // Process trackingData as needed
      } catch (error) {
          if (error.name === 'AbortError') {
              console.log('Request was aborted');
          } else {
              console.error('Error fetching asset tracking data:', error);
          }
      }
    }
  
    setForms(newForms);
    setHasUnsavedChanges(true);
  };  

  const handleImageChange = (index, file) => {
    const newForms = [...forms];
    newForms[index] = { ...newForms[index], image: file, imageName: file.name};
    console.log(file)
    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const handleSaveDraft = async () => {

    await AxiosInstance.delete('/api/delete_unsaved_entries/');
    
    try {
      const draftForms = await Promise.all(
        forms.map(async (form, index) => {
          if (form.collapsed) {
            return form;
          }
          const formData = new FormData();
          formData.append('projectId', form.projectId);
          formData.append('assetType', form.assetType);
          formData.append('assetName', form.assetName);
          formData.append('process', form.process);
          formData.append('status', form.status);
          formData.append('cManDays', form.cManDays);
          formData.append('manDays', form.manDays);
          formData.append('description', form.description);
          formData.append('isSubmitted', false); // Mark this as a draft
          if (form.image) {
            formData.append('image', form.image);
          }
  
          await AxiosInstance.post(`/api/daily_log/`, formData);
  
          return {
            ...form,
            prefilled: true,  // Mark as prefilled after successful draft save
            collapsed: false,  // Collapse the form after saving
          };
        })
      );
  
      alert('Log entries saved as draft successfully!');
      setForms(draftForms); // Update forms with saved draft data
      setHasUnsavedChanges(false);
      setFormErrors([]);
    } catch (error) {
      console.error('Error saving drafts:', error);
      alert('Failed to save drafts.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages([]);
  
    const validationResults = forms.map(validateForm);
    const hasErrors = validationResults.some((error) => Object.keys(error).length > 0);
  
    // Sum cManDays across all forms
    const totalCManDays = forms.reduce((sum, form) => sum + (parseFloat(form.cManDays) || 0), 0);

    if (totalCManDays > 1) {
      setErrorMessages(["The total Time Spent across all forms should not exceed 1."]);
      setIsModalOpen(true); // Show error modal
      // return;
    }

    if (hasErrors) {
      setFormErrors(validationResults); // Store form errors
      const errorMessages = validationResults
        .map((errors, index) => {
          const fieldErrors = Object.entries(errors)
            .map(([field, errorMessage]) => `${field}: ${errorMessage}`)
            .join(', ');
          return `Form ${index + 1}${fieldErrors ? ' ': ' no '}has issues: ${fieldErrors}`;
        })
        .filter(Boolean); // Filter out any empty error strings

        setErrorMessages((prevErrorMessages) => [
          ...prevErrorMessages,    // Retain previous error messages
          ...errorMessages      // Append new error messages
        ]);
      setIsModalOpen(true); // Show error modal
      return;
    }

    // Open the modal for confirmation
    setFormToSubmit(forms); // Store the forms to submit later
    setIsModalOpen(true); // Show confirmation modal
  };

  const handleConfirm = async () => {
    setIsModalOpen(false); // Close the modal
  
    try {
      const updatedForms = await Promise.all(
        formToSubmit.map(async (form, index) => {
          if (form.collapsed) {
            return form;
          }
          const formData = new FormData();
          formData.append('projectId', form.projectId);
          formData.append('assetType', form.assetType);
          formData.append('assetName', form.assetName);
          formData.append('process', form.process);
          formData.append('status', form.status);
          formData.append('cManDays', form.cManDays);
          formData.append('manDays', form.manDays);
          formData.append('description', form.description);
          formData.append('isSubmitted', true); // Mark this as a draft
          if (form.image) {
            formData.append('image', form.image);
          }
  
          await AxiosInstance.post(`/api/daily_log/`, formData);
  
          return {
            ...form,
            prefilled: true,
            collapsed: true,
          };
        })
      );

      await AxiosInstance.delete('/api/delete_unsaved_entries/');
  
      alert('Log entries created successfully!');
      // Proceed with form submission after confirming
      setForms(updatedForms);
      setHasUnsavedChanges(false);
      setFormErrors([]);
    } catch (error) {
      console.error('Error creating log entries:', error);
      alert('Failed to create log entries.');
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close the modal if the user cancels
  };

  const addForm = () => {
    const newForms = forms.map((form) => ({ ...form }));
    newForms.push({ ...initialFormState, collapsed: false });
    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const deleteForm = (index) => {
    const newForms = forms.filter((_, i) => i !== index);

    // Adjust the file input references after deleting a form
    fileInputRefs.current = fileInputRefs.current.filter((_, i) => i !== index);

    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const clearForm = (index) => {
    const newForms = [...forms];
    newForms[index] = { ...initialFormState, image: null };

    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].value = '';
    }
    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const validateForm = (form) => {
    let errors = {};
    
    if (!form.projectId) errors.projectId = 'Project is required';
    if (!form.assetType) errors.assetType = 'Asset Type is required';
    if (!form.assetName) errors.assetName = 'Asset Name is required';
    if (!form.process) errors.process = 'Process is required';
    if (!form.status) errors.status = 'Status is required';
    if (!form.cManDays) errors.cManDays = 'Time Spent is required';
    if (!form.manDays) errors.manDays = 'Estimated Time is required';
    if (!form.description) errors.description = 'Description is required';
    if (!form.image) errors.image = 'Image is required';

    return errors; // Return all validation errors
  };

  const isFormValid = forms.every((form) => Object.keys(validateForm(form)).length === 0);
  const isAllPrefilled = forms.every(form => form.prefilled); // use to check if all draft or new update available
  const isAllCollapsed = forms.every(form => form.collapsed);

  const getInputClassName = (index, field) => {
    return formErrors[index]?.[field] ? 'error-border' : '';
  };

  return (
    <div className='pd'>
      <Header />
      {/* Banner section */}
      <div className="project-banner">
        {projectInfo.bannerUrl ? (
          <img src={projectInfo.bannerUrl} alt={`${projectInfo.name} Banner`} className="project-banner-image" />
        ) : (
          <h2 className="project-banner-title">{projectInfo.name}</h2>
        )}   
      </div>
      <form onSubmit={handleSubmit}>
        <div className='dailylogform'>
          {/* <p>Daily log for {new Date().toLocaleDateString()}</p> */}
          <table className='form-table'>
            <thead className='form-header'>
              <tr>
                <th>Project</th>
                <th>Asset Type</th>
                <th>Asset Name</th>
                <th>Process</th>
                <th>Status</th>
                <th>Time Spent</th>
                <th>Estimated Time</th>
                <th>Description</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {forms.map((form, index) => (
                <tr key={index} className={form.collapsed ? 'form-row disabled' : 'form-row editable'} >
                  <td>
                    <select 
                      value={form.projectId} 
                      onChange={(e) => handleInputChange(index, 'projectId', e.target.value)}
                      className={getInputClassName(index, 'projectId')}
                      disabled={form.collapsed}
                    >
                      <option value="">Select...</option>
                      {projectOptions.map((project) => (
                        <option key={project.id} value={project.id}>
                          {project.id}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={form.assetType}
                      onChange={(e) => handleInputChange(index, 'assetType', e.target.value)}
                      className={getInputClassName(index, 'assetType')}
                      disabled={form.collapsed || !form.projectId} // Disable if projectId is not selected
                    >
                      <option value="">Select...</option>
                      {/* Check if assetTypeOptions for the selected projectId exists and map over it */}
                      {form.projectId &&
                        form.assetTypeOptions.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={form.assetName}
                      onChange={(e) => handleInputChange(index, 'assetName', e.target.value)}
                      className={getInputClassName(index, 'assetName')}
                      disabled={form.collapsed || !form.assetType}
                    >
                      <option value="">Select...</option>
                      {form.assetType && form.projectId && 
                        assetNameOptions[form.projectId][form.assetType]?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={form.process}
                      onChange={(e) => handleInputChange(index, 'process', e.target.value)}
                      className={getInputClassName(index, 'process')}
                      disabled={form.collapsed}
                    >
                      <option value="">Select...</option>
                      {processOptions.map((process) => (
                        <option key={process.id} value={process.id}>
                          {process.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={form.status}
                      onChange={(e) => handleInputChange(index, 'status', e.target.value)}
                      className={getInputClassName(index, 'status')}
                      disabled={form.collapsed}
                    >
                      <option value="">Select...</option>
                      {statusOptions.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={form.cManDays}
                      onChange={(e) => handleInputChange(index, 'cManDays', e.target.value)}
                      className={getInputClassName(index, 'cManDays')}
                      min="0.05"
                      max="1"
                      step="0.05"
                      placeholder="Select..."
                      disabled={form.collapsed}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={form.manDays}
                      onChange={(e) => handleInputChange(index, 'manDays', e.target.value)}
                      className={getInputClassName(index, 'manDays')}
                      min="0"
                      step="any"
                      placeholder="Select..."
                      disabled={form.collapsed || form.tracked}
                    />
                  </td>
                  <td>
                    <textarea
                      value={form.description}
                      onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                      className={getInputClassName(index, 'description')}
                      placeholder='Enter details or comments...'
                      disabled={form.collapsed}
                    />
                  </td>
                  <td>
                    <ImageUploadComponent 
                      index={index}
                      form={form}
                      handleImageChange={handleImageChange}
                      fileInputRefs={fileInputRefs}
                      imageName={form.imageName}
                    />
                  </td>
                  <td>
                    <div className='action-bar'>
                      {!form.collapsed ? (
                        <>
                          <button type="button" onClick={() => deleteForm(index)} className="action-bar-button delete">
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                          <button type="button" onClick={() => clearForm(index)} className="action-bar-button clear">
                            <FontAwesomeIcon icon={faUndoAlt} />
                          </button>
                          <button type="button" onClick={() => handleSaveDraft(index)} className="action-bar-button save">
                            <FontAwesomeIcon icon={faSave} /> {/* Save icon */}
                          </button>
                        </>
                      ) : (
                        <button 
                          type="button" 
                          // onClick={() => toggleCollapse(index)} 
                          className={"delete-form-button-collapsed"}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
              {/* <tr>
                <td colSpan="5"></td>
                <td>
                  <strong>
                    Cumulative Time Spent: 
                    {forms.reduce((total, form) => total + (parseFloat(form.cManDays) || 0), 0).toFixed(2)} hours
                  </strong>
                </td>
                <td colSpan="4"></td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className="form-actions">
          <div className="cumulative-time-spent">
            <span>Cumulative Time Spent: </span>
            <strong
              style={{
                color: forms.reduce((total, form) => total + (parseFloat(form.cManDays) || 0), 0) > 1 ? 'red' : 'inherit',
              }}
            >
              {forms.reduce((total, form) => total + (parseFloat(form.cManDays) || 0), 0).toFixed(2)} man-days
            </strong>
          </div>
          <button type="button" onClick={addForm} className="add-button">
            <FontAwesomeIcon icon={faPlus} /> Add Task
          </button>
          {/* <button type="submit" className="submit-button" disabled={!isFormValid || forms.length === 0 || isAllPrefilled}> */}
          <button type="submit" className="submit-button" disabled={forms.length === 0 || isAllCollapsed} >
            Submit
          </button>
        </div>
      </form>
      <ConfirmationModal
        isOpen={isModalOpen}
        message={
          errorMessages.length > 0 
            ? errorMessages.join('\n\n') 
            : `Are you sure you want to submit the log entries for ${
                new Date().getHours() >= 12 
                  ? new Date().toLocaleDateString() // After 12 noon, show today's date
                  : new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString() // Before 12 noon, show yesterday's date
              }? The submitted entries cannot be editted!` +
              (forms.reduce((total, form) => total + (parseFloat(form.cManDays) || 0), 0) < 1 
                ? '\n\nWarning: The total Time Spent is less than 1.' 
                : '')
        }
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        isError={errorMessages.length > 0 }
      />
    </div>
  );
};

export default ProjectDetail;
